import React, { Component } from 'react';
import axios from 'axios';
export default class Form extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSuccess: false,
			isLoading: false,
			email: '',
			email_touched: false,
			email_valid: false,
			message: '',
			message_touched: false,
			message_valid: false,
		};
	}

	update(e) {
		const name = e.target.name;
		const value = e.target.value;
		let valid = value.length > 2;
		if (name === 'email') {
			valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
		}
		this.setState({
			[name]: value,
			[`${name}_touched`]: true,
			[`${name}_valid`]: valid,
		});
	}

	submit(e) {
		const { email, message, email_valid, message_valid } = this.state;
		this.setState({ isLoading: true, message_touched: true, email_touched: true });
		if (!email_valid || !message_valid) {
			return;
		}
		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			params: {
				email,
				message,
			},
		};
		axios
			.post('https://hooks.zapier.com/hooks/catch/3122276/e6ushz/', {}, config)
			.then(res => {
				this.setState({ isLoading: false, isSuccess: true });
			})
			.catch(e => console.log(e));
	}

	render() {
		const { isSuccess, isLoading, email, email_valid, email_touched, message_touched, message_valid, message } = this.state;

		return (
			<React.Fragment>
				{isSuccess ? (
					<div className="thank-you">
						<h2>Your message has been sent.</h2>
					</div>
				) : (
					<form key="main_contact_form">
						<label for="email" className="sr-only">
							Your Email
						</label>
						<input
							className={!email_valid && email_touched ? `error` : ''}
							key="main_contact_form__input"
							onChange={this.update.bind(this)}
							name="email"
							type="email"
							value={email}
							placeholder="Your Email"
						/>
						{!email_valid && email_touched && <div className="validation">Please use a valid email.</div>}
						<label for="message" className="sr-only">
							Your Message
						</label>
						<textarea
							className={!message_valid && message_touched ? `error` : ''}
							key="main_contact_form__textarea"
							onChange={this.update.bind(this)}
							name="message"
							type="text"
							value={message}
							placeholder="Your Message"
						/>
						{!message_valid && message_touched && <div className="validation">Please use a longer message.</div>}
						<div className="center-button">
							<button
								type="submit"
								disabled={isLoading}
								onClick={this.submit.bind(this)}
								className={`${isLoading ? 'loader' : 'button'}`}
							>
								Send
							</button>
						</div>

						{/* <div className="letter-wrapper centered">
							<article className="letter">
								<div className="side">
									<h2>Sophia,</h2>
									<p>
										<textarea placeholder="Your message" />
									</p>
								</div>
								<div className="side">
									<p>
										<input type="text" placeholder="Your name" />
									</p>
									<p>
										<input type="email" placeholder="Your email" />
									</p>
									<p>
										<button id="sendLetter">Send</button>
									</p>
								</div>
							</article>
							<div className="envelope front" />
							<div className="envelope back" />
						</div> */}
						{/* <p class="result-message centered">Thank you for your message</p> */}
					</form>
				)}
			</React.Fragment>
		);
	}
}
