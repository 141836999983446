import React, { Component } from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import Template from './../layouts/Template';
import Form from './../components/Form';

export default class Contact extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const StyledContact = styled.section`
			${tw`pt-24`};
			.title-big {
				${tw`text-center sm:text-128`};
				top: -0.55em;
				margin-bottom: -0.35em;
			}
			form {
				${tw`mx-auto pt-12 pb-8 px-6 bg-white rounded-lg`};
				max-width: 600px;
				input,
				textarea {
					${tw`w-full py-3 px-2 mb-4 bg-grey-lightest rounded-md`};
					&:focus {
						outline: none;
					}
				}
				textarea {
					min-height: 8rem;
				}
				button {
					${tw`mx-auto`};
				}
				.sr-only {
					display: none;
				}
				.validation {
					${tw`text-left mb-5`};
				}
			}
			.thank-you h2 {
				${tw`font-round text-center`};
			}
		`;

		return (
			<Template title={`Contact | ${this.props.data.site.siteMetadata.title}`} desc="Contact me here.">
				<StyledContact>
					<div className="wrapper">
						<div className="container">
							<div className="box">
								<header className="title-big">
									<h1>Contact</h1>
									<div className="subtitle">Say hello.</div>
								</header>
								<div className="contact-form">
									<Form />
								</div>
							</div>
						</div>
					</div>
				</StyledContact>
			</Template>
		);
	}
}

export const pageQuery = graphql`
	query ContactQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
